<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item 
      v-for="(r, index) in routes.filter((key) => key !== 'work')" 
      :key="routeMap[r].route" 
      :to="{ path: routeMap[r].route }"
    >
      {{routeMap[r].name}}
    </el-breadcrumb-item>
    <el-breadcrumb-item>
      {{routeMap[last] ? routeMap[last].name : last}}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  data () {
    return {
      routeMap: {
        "": {},
        work: {
          name: 'Home',
          route: '/work'
        },
        customers: {
          name: 'Customer',
          route: '/work/customers'
        },
        app: {
          name: 'App Releases',
          route: '/work/app'
        },
        model: {
          name: 'Model Releases',
          route: '/work/model'
        },
        users: {
          name: 'Users',
          route: '/work/users'
        },
        logs: {
          name: 'Logs',
          route: '/work/logs'
        }
      },
      routes: [],
      last: ''
    }
  },
  mounted() {
    let routes = this.$route.path.split('/')
    routes.shift()
    this.last = routes.pop()
    this.routes = routes
  },
  methods: {
  }
}
</script>

<style>
.el-breadcrumb__item {
  font-size: 18px;
  line-height: 50px;
}
</style>
